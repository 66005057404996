
#contact-screen-photo-strip {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

#contact-screen-photo-strip  #profile-img {
    border-radius: 50em;
    width: 13em;
    height: 13em;
    margin: 3em 0em
}

#contact-screen-text {
    width: 20em;
    height: fit-content;
    vertical-align: middle;
    color: gray;
    margin-left: 3em;
}

#contact-screen-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#contact-info-item {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    margin-bottom: 2em;
    cursor: pointer;
}

#contact-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5em;
    color: rgb(31, 31, 31)
}

#contact-img {
    border-radius: 50em;
    width: 3em;
    height: 3em;
}

#contact-info-item-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#contact-info-item-text {
    height: fit-content;
    vertical-align: middle;
    color: gray;
    margin-left: 1em;
}