
#contact-screen-photo-strip {
    display: flex;
    justify-content: center;
    vertical-align: middle;
}

#mobile-contact-screen-profile-img {
    border-radius: 50em;
    width: 10em;
    height: 10em;
    margin: 1em 0em;
    margin-bottom: -0.2em;
}

#contact-screen-text {
    width: 20em;
    height: fit-content;
    vertical-align: middle;
    color: gray;
    margin-left: 3em;
}

#contact-screen-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#mobile-contact-info-item {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    margin-bottom: 2em;
    cursor: pointer;
    font-size: 1.1em
}

#contact-img-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.5em;
    color: rgb(31, 31, 31)
}

#contact-img {
    border-radius: 50em;
    width: 3em;
    height: 3em;
}

#contact-info-item-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#mobile-contact-info-item-text {
    height: fit-content;
    vertical-align: middle;
    color: gray;
    margin-left: 0.5em;
    font-size: 1.3em
}