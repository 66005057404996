
.mobile-preview-card {
    width: fit-content;
    font-size: 1.3em;
    margin: 0em 1em;
}

h3 {
    color: darkslategrey;
}

.mobile-preview-card p {
    color: grey;
    text-align: left;
}

.mobile-item-container {
    margin-top: -0.7em;
    display: flex;
}

.description{
    margin-left: 1em;
}

.mobile-preview-card nav {

    margin-top: -1em;
    margin-bottom: 1em;
    display: flex;
    align-content: center;
    justify-content: center;
}