
#drawer-menu-container{
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    z-index: 10;
}

#drawer-menu {

    background-color: #333333;
    width: 18em;
    z-index: 10;
}

#drawer-menu-shadow-wall {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #333333;
    opacity: 30%;
    z-index: 9;
}

.drawer-button {

    display: flex;
    align-items: center;
    height: 3.5em;
    border-bottom: 1px solid black;
    padding-left: 1.5em;
    font-size: 1.2em;
    color: #ffffff;
    cursor: pointer;
}

.drawer-button:active {
    background-color: #111111;
}

#mobile-nav-bar {
    background-color: #222222;
    height: 1em;
    color: white;
    font-size: 2.2em;
    padding: 0.3em;
    z-index: 8;
}