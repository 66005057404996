.centering-container {

    width: 100%;
    text-align: center;
}

#title-section {

    display: flex;
    justify-content: center;

}

#profile-img-container {
    border-radius: 10em;
}

#profile-img {
    border-radius: 50em;
    width: 22em;
    height: 22em;
    margin: 3em 0em
}

#name-title-container {

    margin-top: 5%;
    margin-right: 7%;
}

#name-title-container h1 {

    color: rgb(255, 196, 0);
    font-size: 2.5em;
}

#name-title-container h3 {

    color: #ffffff;
}

#about-section {
    display: inline-block;
    margin: 0 auto;
    padding: 1.5em 9em;
    font-size: 1.3em;
    width: 43em;
}

#about-section h3 {
    text-align: left;
}

#preview-section-container {
  margin-bottom: 5em;
  padding-top: 3em;
}

#previews-section {
    margin: 0 auto;
    width: 70em;
    display: flex;
    justify-content: center;
}

.vl {
    border-left: 2px solid gray;
    margin: 0em 4em
  }

.hl {
    display: inline-block;
    margin: 0 auto;
    border-top: 2px solid gray;
    width: 75em;
  }