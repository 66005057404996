
.project-section {

    display: flex;
    justify-content: center;
    vertical-align: middle;
}

.project-section .vl {

    border-left: 2px solid gray;
    margin: 0em 0em
}

.project-section-column {

    display: flex;              /* establish flex container */
    flex-direction: column;     /* stack flex items vertically */
    justify-content: center;
    
}

.project-section-column-left {
    align-items: flex-end;
}

.project-section-container {
    
    padding: 2em 3em;
}

.project-section {

    margin-top: 2em;
    margin-bottom: 4em
}

.project-description h2{
    color: #2079c7;
    margin-bottom: -0.3em
}

.project-description h3{
    margin-bottom: -0.4em
}

.project-description-text {
    margin-top: 2.5em;
    margin-bottom: 2em
}