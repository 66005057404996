
.sub-page-title-section{

    display: flex;
    flex-direction: column;
    color: rgb(255, 196, 0);
    font-size: 1.5em;
    padding-top: 1.5em;
    padding-right: 22em;
    align-items: center;
    height: 185px;
}

.mobile-sub-page-title-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: rgb(255, 196, 0);
    font-size: 1.3em;
    align-items: center;
    height: 185px;
    margin-top: -0.5em
}

.about-button {

    display: flex;
    align-items: center;
    height: 2.5em;
    width: 15em;
    margin-top: 1.5em;
    margin-left: 0.5em;
    border: 2px solid grey;
    padding-left: 0.7em;
    color: darkslategrey;
    cursor: pointer;
}

.about-button:hover {
    background-color: lightgray;
}

.about-button:active{
    background-color: gray;
}

.about-button h4 {
    margin-left: 0.5em;
}

.long-button {
    width: 20em
}

.list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    margin: 0.5em 0em;
}




.mobile-about-button {

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 2em;
    width: 10em;
    margin-top: 1.5em;
    border: 2px solid grey;
    color: darkslategrey;
    cursor: pointer;
}

.mobile-about-button:hover {
    background-color: lightgray;
}

.mobile-about-button:active{
    background-color: gray;
}

.mobile-about-button h4 {
    margin-left: 0.5em;
}

.mobile-long-button {
    width: 100%;
}

.mobile-long-button h4 {
    margin-left: 0;
}