
.mobile-experience-description {
    width: fit-content;
    font-size: 1.5em;
    margin: 0em 1em;
}

.mobile-experience-description h3 {
    color: #2079c7;
    margin-top: 0em
}

.mobile-experience-description h4 {
    color: darkslategrey;
    margin-top: -0.5em
}

.mobile-experience-description h5 {
    margin-top: -0.5em
}

.mobile-experience-description  p {
    color: grey;
    text-align: left;
    margin-top: -0.5em;
    font-size: 0.9em
}

.experience-description-item-container {
    margin-top: -0em;
    display: flex;
}

.mobile-experience-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    margin-top: -1.2em;
    margin-bottom: 1.5em
}

.mobile-experience-links .mobile-about-button {
    margin-top: 0.6em
}

.mobile-experience-links h4{
    margin-top: 1.5em;
    font-size: 0.9em;
}