
#mobile-backdrop-img {
    width: 100%;
    position: absolute;
    background-color: #04010a;
    background-image: url("../../assets/mobile_code_bg.jpg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: left top;
    z-index: -10;
}